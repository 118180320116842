import React, { useEffect, useState } from "react"

import SEO from "@/components/seo"
import Sizing from "@/components/general/Sizing"
import styles from "@/styles/four-oh-four.module.scss"

import fourOhfour from "@/images/404.png"
import { Link } from "gatsby"

const NotFoundPage = () => {
    const [visibility, setVisibility] = useState("hidden")

    useEffect(() => {
        setVisibility("visible")
    }, [])

    return (
        <>
            <SEO title="Markets Improve Everything | Page not found" />

            <div className={styles.main} style={{ visibility }}>
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                maxWidth: "400px",
                                marginBottom: 1,
                            },
                        },

                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <img src={fourOhfour} alt="" />
                    <span className={"copy--lead"}>
                        The page you are looking for can’t be found
                    </span>

                    <Link to="/es/" className={"copy"}>
                        Return to homepage
                    </Link>
                </Sizing>
            </div>
        </>
    )
}

export default NotFoundPage
